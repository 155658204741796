.menu-item {
    height: 64px;
    padding: 15px;
    padding-top: 22px;
    cursor: pointer;
    text-transform: uppercase;
    color: grey;
    font-size: 14px;


    &:hover {
        background-color: rgba(25, 118, 210, 0.04);
    }
}